<template>
  <div>
    <h1>Post Detail</h1>
    <p>Base Path: {{ basePath }}</p>
    <p>Post ID: {{ postId }}</p>
    <!-- 这里可以根据 basePath 和 postId 发起 API 请求或显示详情 -->
  </div>
</template>

<script>
export default {
  computed: {
    // 从 Vuex 中获取 basePath 和 postId
    basePath() {
      return this.$store.state.basePath;
    },
    postId() {
      return this.$store.state.postId;
    }
  },
  mounted() {
    // 在组件挂载时打印 basePath 和 postId
    console.log('Base Path:', this.basePath);
    console.log('Post ID:', this.postId);

    // 可以在此处根据 basePath 和 postId 发起请求获取数据
  }
};
</script>

<style scoped>
/* 样式根据需求 */
</style>
